import { questions } from '../assets/SurveyQuestions';
import { sections } from '../assets/SurveySections';

export const ACTIONS = {
    SET_CHECKBOX_ANSWER: 'set_checkbox_answer',
    SET_RADIO_ANSWER: 'set_radio_answer',
    CHANGE_ACTIVE_SECTION: 'change_active_section',
    GET_POINTS: 'get_points',
    SET_FIRST_NAME: 'set_first_name',
    SET_LAST_NAME: 'set_last_name',
    SET_PESEL: 'set_pesel',
}

export const INITIAL_STATE = {
    clientInfo: {
        firstName: '',
        lastName: '',
        pesel: ''
    },
    sections: sections,
    questions: questions
};

export const questionsReducer = (state, action) => {

    state.questions = disableQuestions(state.questions);

    switch (action.type) {
        case ACTIONS.SET_FIRST_NAME: {
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    firstName: action.payload.firstName
                }
            }
        }
        case ACTIONS.SET_LAST_NAME: {
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    lastName: action.payload.lastName
                }
            }
        }
        case ACTIONS.SET_PESEL: {
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    pesel: action.payload.pesel
                }
            }
        }
        case ACTIONS.SET_CHECKBOX_ANSWER: {
            const newState = state.questions.map(question => {
                if (question.id === action.payload.questionId) {
                    return {
                        ...question,
                        answers: question.answers.map(answer => {
                            if (action.payload.answer.uncheckOthers) {
                                if (answer.id === action.payload.answer.id) {
                                    return {
                                        ...answer,
                                        checked: !answer.checked
                                    }
                                } else {
                                    return {
                                        ...answer,
                                        checked: false
                                    }
                                }
                            }

                            if (!action.payload.answer.uncheckOthers) {
                                if (answer.uncheckOthers) {
                                    return {
                                        ...answer,
                                        checked: false
                                    }
                                }
                            }

                            if (answer.id === action.payload.answer.id) {
                                return {
                                    ...answer,
                                    checked: !answer.checked
                                }
                            }
                            return answer;
                        })
                    }
                }
                return question;
            });

            return {
                ...state,
                questions: refreshRequired(disableQuestions(newState))
            };
        };
        case ACTIONS.SET_RADIO_ANSWER: {
            const newState = state.questions.map(question => {
                if (question.id === action.payload.questionId) {
                    return {
                        ...question,
                        answers: question.answers.map(answer => {

                            if (answer.id === action.payload.answer.id) {
                                return {
                                    ...answer,
                                    checked: true
                                }
                            }

                            return {
                                ...answer,
                                checked: false
                            }
                        })
                    }
                }
                return question;
            });

            return {
                ...state,
                questions: refreshRequired(disableQuestions(newState))
            };
        };
        case ACTIONS.CHANGE_ACTIVE_SECTION: {
            if (!action.payload.activeSectionId) {
                return state;
            }

            return {
                ...state,
                sections: state.sections.map(section => {
                    if (section.id === action.payload.activeSectionId) {
                        return {
                            ...section,
                            active: true
                        }
                    }

                    return {
                        ...section,
                        active: false
                    }
                })
            };
        }
        default:
            return state;
    }
}

const disableQuestions = (questions) => {
    const questionsToDisable = checkForDisabledQuestions(questions);

    return questions.map(question => {
        if (questionsToDisable.includes(question.id)) {
            return {
                ...question,
                disabled: true,
                answers: question.answers.map(answer => {
                    return {
                        ...answer,
                        checked: false
                    }

                })
            };
        } else {
            return {
                ...question,
                disabled: false
            };
        }
    })
}

const checkForDisabledQuestions = (questions) => {
    return questions
        .flatMap(question => question.answers)
        .filter(answer => answer.checked === true)
        .flatMap(answer => answer.disableQuestion);
}

const refreshRequired = (questions) => {
    return questions
        .map(question => {
            if (question.multipleAnswers && !question.disabled && question.answers.filter(answer => answer.checked).length > 0) {
                return {
                    ...question,
                    required: false
                }
            }

            return {
                ...question,
                required: true
            }
        });
}

const isChecked = (questions, answerId) => {
    return questions
        .flatMap(question => question.answers)
        .find(answer => answer.id === answerId).checked;
}

const getPoints = (questions) => {
    return questions
        .flatMap(question => question.answers)
        .filter(answer => answer.checked)
        .flatMap(answer => answer.points)
        .reduce((totalPoints, points) => totalPoints + points, 0);
}

const checkIfAllAnswered = (questions) => {
    const unansweredCount = questions
        .map(question => {
            if (question.disabled) {
                return true;
            }

            return question.answers.filter(answer => answer.checked).length > 0;
        })
        .filter(e => e === false)
        .length;

    return unansweredCount === 0;
}

const isValidForStartups = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 7 &&
            (
                (isChecked(questions, "4_1_1_2") || isChecked(questions, "4_1_1_3") || isChecked(questions, "4_1_1_4"))
                || (isChecked(questions, "4_1_2_2") || isChecked(questions, "4_1_2_3") || isChecked(questions, "4_1_2_4"))
            ) &&
            (
                (isChecked(questions, "4_2_1_2") || isChecked(questions, "4_2_1_3") || isChecked(questions, "4_2_1_4"))
                || (isChecked(questions, "4_2_2_2") || isChecked(questions, "4_2_2_3") || isChecked(questions, "4_2_2_4"))
            ))
        || (points >= 7 && isChecked(questions, "4_3_1"))
        || points >= 9)
        && (isChecked(questions, "5_2") || isChecked(questions, "5_3"))
        && isChecked(questions, "6_3")
        && isChecked(questions, "8_3")
        && isChecked(questions, "9_3");
}

const isValidForGrowingAndMatureShares = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 7 &&
            (
                (isChecked(questions, "4_1_1_2") || isChecked(questions, "4_1_1_3") || isChecked(questions, "4_1_1_4"))
                || (isChecked(questions, "4_1_2_2") || isChecked(questions, "4_1_2_3") || isChecked(questions, "4_1_2_4"))
            ) &&
            (
                (isChecked(questions, "4_2_1_2") || isChecked(questions, "4_2_1_3") || isChecked(questions, "4_2_1_4"))
                || (isChecked(questions, "4_2_2_2") || isChecked(questions, "4_2_2_3") || isChecked(questions, "4_2_2_4"))
            ))
        || (points >= 7 && isChecked(questions, "4_3_1"))
        || points >= 8)
        && (isChecked(questions, "5_1") || isChecked(questions, "5_2") || isChecked(questions, "5_3"))
        && (isChecked(questions, "6_2") || isChecked(questions, "6_3"))
        && (isChecked(questions, "8_2") || isChecked(questions, "8_3"))
        && (isChecked(questions, "9_2") || isChecked(questions, "9_3"));
}

const isValidForCorporateBonds = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 7 &&
            (
                (isChecked(questions, "4_1_3_2") || isChecked(questions, "4_1_3_3") || isChecked(questions, "4_1_3_4"))
            ) &&
            (
                (isChecked(questions, "4_2_3_2") || isChecked(questions, "4_2_3_3") || isChecked(questions, "4_2_3_4"))
            ))
        || (points >= 7 && isChecked(questions, "4_3_2"))
        || points >= 9)
        && (isChecked(questions, "5_2") || isChecked(questions, "5_3"))
        && isChecked(questions, "6_3")
        && (isChecked(questions, "8_2") || isChecked(questions, "8_3"))
        && isChecked(questions, "9_3");
}

const isValidForBonds = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 7 &&
            (
                (isChecked(questions, "4_1_3_2") || isChecked(questions, "4_1_3_3") || isChecked(questions, "4_1_3_4"))
            ) &&
            (
                (isChecked(questions, "4_2_3_2") || isChecked(questions, "4_2_3_3") || isChecked(questions, "4_2_3_4"))
            ))
        || (points >= 7 && isChecked(questions, "4_3_2"))
        || points >= 9)
        && (isChecked(questions, "5_2") || isChecked(questions, "5_3"))
        && isChecked(questions, "6_3")
        && (isChecked(questions, "8_2") || isChecked(questions, "8_3"))
        && isChecked(questions, "9_3");
}

const isValidForShares = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 7 &&
            (
                (isChecked(questions, "4_1_1_2") || isChecked(questions, "4_1_1_3") || isChecked(questions, "4_1_1_4"))
                || (isChecked(questions, "4_1_2_2") || isChecked(questions, "4_1_2_3") || isChecked(questions, "4_1_2_4"))
            ) &&
            (
                (isChecked(questions, "4_2_1_2") || isChecked(questions, "4_2_1_3") || isChecked(questions, "4_2_1_4"))
                || (isChecked(questions, "4_2_2_2") || isChecked(questions, "4_2_2_3") || isChecked(questions, "4_2_2_4"))
            ))
        || (points >= 7 && isChecked(questions, "4_3_1"))
        || points >= 8)
        && (isChecked(questions, "5_1") || isChecked(questions, "5_2") || isChecked(questions, "5_3"))
        && (isChecked(questions, "6_2") || isChecked(questions, "6_3"))
        && (isChecked(questions, "8_2") || isChecked(questions, "8_3"))
        && (isChecked(questions, "9_2") || isChecked(questions, "9_3"));
}

const onlyESG = (questions) => {
    return isChecked(questions, "7_1_1") && isChecked(questions, "7_2_2");
}

const isValidSharesKnowledge = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 6 &&
            (
                (isChecked(questions, "4_1_1_2") || isChecked(questions, "4_1_1_3") || isChecked(questions, "4_1_1_4"))
                || (isChecked(questions, "4_1_2_2") || isChecked(questions, "4_1_2_3") || isChecked(questions, "4_1_2_4"))
            ) &&
            (
                (isChecked(questions, "4_2_1_2") || isChecked(questions, "4_2_1_3") || isChecked(questions, "4_2_1_4"))
                || (isChecked(questions, "4_2_2_2") || isChecked(questions, "4_2_2_3") || isChecked(questions, "4_2_2_4"))
            ))
    ) || (
            (points >= 6) &&
            (isChecked(questions, "4_3_1"))
        ) || (
            (points >= 10)
        )
}

const isValidBondsKnowledge = (questions) => {
    const points = getPoints(questions);
    return (
        (points >= 6 &&
            (
                (isChecked(questions, "4_1_3_2") || isChecked(questions, "4_1_3_3") || isChecked(questions, "4_1_3_4"))
            ) &&
            (
                (isChecked(questions, "4_2_3_2") || isChecked(questions, "4_2_3_3") || isChecked(questions, "4_2_3_4"))
            ))
    ) || (
            points >= 6 &&
            isChecked(questions, "4_3_2")
        )
        || points >= 10;
}

const isValidSharesHorizon = (questions) => {

    return (isChecked(questions, "5_1") || isChecked(questions, "5_2") || isChecked(questions, "5_3")) &&
        (isChecked(questions, "6_2") || isChecked(questions, "6_3")) &&
        (isChecked(questions, "8_2") || isChecked(questions, "8_3")) &&
        (isChecked(questions, "9_2") || isChecked(questions, "9_3"));
}

const isValidBondsHorizon = (questions) => {
    return (!isChecked(questions, "5_1") || isChecked(questions, "5_2") || isChecked(questions, "5_3")) &&
        (isChecked(questions, "6_3")) &&
        (isChecked(questions, "8_2") || isChecked(questions, "8_3")) &&
        (isChecked(questions, "9_3"));
}

export const getSurveyResults = (questions) => {

    const allAnswered = checkIfAllAnswered(questions);
    const points = getPoints(questions);
    const startups = isValidForStartups(questions);
    const sharesGrowingAndMature = isValidForGrowingAndMatureShares(questions);
    const corporateBonds = isValidForCorporateBonds(questions);
    const bonds = isValidForBonds(questions);
    const shares = isValidForShares(questions);
    const esg = onlyESG(questions);
    const sharesKnowledge = isValidSharesKnowledge(questions);
    const bondsKnowledge = isValidBondsKnowledge(questions);
    const sharesHorizon = isValidSharesHorizon(questions);
    const bondsHorizon = isValidBondsHorizon(questions);

    return {
        allAnswered: allAnswered,
        points: points,
        bonds: bonds,
        shares: shares,
        bondsHorizon: bondsHorizon,
        bondsKnowledge: bondsKnowledge,
        sharesHorizon: sharesHorizon,
        sharesKnowledge: sharesKnowledge,
        sharesGrowingAndMature: sharesGrowingAndMature && !esg,
        startUps: startups && !esg,
        corporateBonds: corporateBonds && !esg,
        sharesGrowingAndMatureESG: sharesGrowingAndMature,
        startUpsESG: startups,
        corporateBondsESG: corporateBonds
    }
}