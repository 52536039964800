import styles from './SurveyWrapper.module.css';

export default function SurveyWrapper({ children }) {
    return (
        <div className={styles['dmuchance-content-wrapper']}>
            <div className={styles['independent-content-inner']}>
                <div className={styles['container']}>
                    {children}
                </div>
            </div>
        </div>
    );
}