import { ACTIONS } from '../../reducers/QuestionsReducer';
import styles from './NavItem.module.css';

export default function NavItem({ number, section, questionsDispatch = () => { } }) {

    const { id, header, active } = section;

    return (
        <li className={[styles['nav-item'], active ? styles['active'] : null].join(' ')} onClick={() => questionsDispatch({ type: ACTIONS.CHANGE_ACTIVE_SECTION, payload: { activeSectionId: id } })}>
            <span className={styles['nav-item-circle']}>{number}</span>
            <div className={styles['nav-item-label']}>{header}</div>
        </li>
    );
}