const Title = ({ children }) => {
  return (
    <p style={{ textAlign: 'center', marginTop: 0, marginBottom: '18.0pt' }}>
      <span style={{ fontWeight: 'bold' }}>{children}</span>
    </p>
  );
};

const TextBlock = ({ children, bold = false }) => {
  return (
    <p style={{ textAlign: 'justify' }}>
      <span style={bold ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>{children}</span>
    </p>
  );
};

const Table = ({ children, center }) => {
  return (
    <table border={1} cellSpacing={0} cellPadding={0}
      style={center
        ? { width: '100%', borderCollapse: 'collapse', border: 'solid black 1.0pt', fontSize: '8.5pt', margin: '0 auto', textAlign: 'center' }
        : { width: '100%', borderCollapse: 'collapse', border: 'solid black 1.0pt', fontSize: '8.5pt' }}>
      {children}
    </table>
  );
};

const TableRow = ({ children, backgroundColor = undefined, height = '17.0pt' }) => {
  return (
    <tr style={{ backgroundColor: backgroundColor, height: height }}>
      {children}
    </tr>
  );
}

const TableCell = ({ children, colSpan = 12 }) => {
  return (
    <td colSpan={colSpan}>
      {children}
    </td>
  );
}

const ClientInfoSection = ({ clientInfo }) => {

  const { firstName, lastName, pesel } = clientInfo;

  const Entry = ({ label, value }) => {
    return (
      <p style={{ margin: '3.6pt' }}>
        <span style={{ fontWeight: 'bold' }}>{label}: </span>
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{value}</span>
      </p>
    )
  }

  return (
    <TableRow backgroundColor="#F2F2F2" height="1.0cm">
      <TableCell colSpan={6} >
        <Entry label={'Imię, nazwisko'} value={firstName + ' ' + lastName} />
      </TableCell>
      <TableCell colSpan={6} >
        <Entry label={'PESEL'} value={pesel} />
      </TableCell>
    </TableRow>
  );
};

const SurveySectionHeader = ({ children, number }) => {
  return (
    <TableRow backgroundColor={"black"}>
      <TableCell colSpan={12}>
        <p style={{ color: 'white', fontWeight: 'bold', margin: '3.6pt', marginLeft: '18.9pt', textIndent: '-12.6pt' }}>
          <span style={{ marginRight: '12px' }}>{number}.</span>
          <span>{children}</span>
        </p>
      </TableCell>
    </TableRow>
  );
};

const SurveyQuestionWrapper = ({ children }) => {

  return (
    <TableRow>
      <TableCell colSpan={12} >
        <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
        {children}
        <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
      </TableCell>
    </TableRow>
  );
}

const SurveyQuestion = ({ number, children, marginLeft = '23.0pt', textIndent = '-18.0pt' }) => {

  return (
    <p style={{ margin: 0, marginLeft: marginLeft, marginRight: '3.6pt', textIndent: textIndent }}>
      <span>{number}<span style={{ fontSize: '7.0pt' }}>&nbsp;&nbsp;</span></span>
      <span>{children}</span>
    </p>
  );
}

const CheckBox = ({ isChecked }) => {
  if (isChecked) {
    return (<span style={{ fontFamily: 'MS Gothic' }}>☑</span>);
  } else {
    return (<span style={{ fontFamily: 'MS Gothic' }}>&#9744;</span>);
  }
};

const SurveyAnswer = ({ children, id, questions, marginLeft = '36.65pt', textIndent = '-12.5pt' }) => {

  const isChecked = (questions, id) => {

    if (!id || questions === undefined) {
      return false;
    }

    return questions
      .flatMap(question => question.answers)
      .find(answer => answer.id === id).checked;
  }

  return (
    <p style={{ margin: 0, marginLeft: marginLeft, marginRight: '3.6pt', textAlign: 'justify', textIndent: textIndent }}>
      <span><CheckBox isChecked={isChecked(questions, id)} />&nbsp;{children}</span>
    </p>
  );
}

const SurveyAnswerHorizontal = ({ children, number = undefined, id, questions }) => {

  const isChecked = (questions, id) => {

    if (!id || questions === undefined) {
      return false;
    }

    return questions
      .flatMap(question => question.answers)
      .find(answer => answer.id === id).checked;
  }

  return (
    <td colSpan={4} style={{ verticalAlign: 'top' }}>
      <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
      <p style={{ margin: 0, marginBottom: '3.0pt', marginLeft: '23.0pt', textIndent: '-18pt' }}>
        <span>{number}&nbsp;&nbsp;</span>
        <span>{children}&nbsp;<CheckBox isChecked={isChecked(questions, id)} /></span>
      </p>
      <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
    </td>
  );
}

const Siganture = ({ color = 'inherit', text = 'podpis', bold = false }) => {
  return (
    <>
      <p style={{ margin: '9.5pt' }}><span>&nbsp;</span></p >
      <p style={{ margin: '3.6pt' }}>
        <span>_______________________________________</span>
      </p>
      <p style={{ margin: '3.6pt', color: color, fontWeight: bold ? 'bold' : 'normal' }}>
        <span>{text}</span>
      </p>
      <p style={{ margin: '3.6pt', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
    </>
  );
}

const ClientClassTable = ({ surveyResults }) => {
  return (
    <Table center>
      <thead>
        <TableRow height="34.0pt">
          <th rowSpan={2} >
            <p>Nazwa grupy docelowej</p>
          </th>
          <th colSpan={2} >
            <p>Klasyfikacja Klienta</p>
          </th>
        </TableRow>
        <TableRow height="34.0pt">
          <th>
            <p>Pozytywna grupa docelowa</p>
          </th>
          <th>
            <p>Negatywna grupa docelowa</p>
          </th>
        </TableRow>
      </thead >
      <tbody>
        <TableRow>
          <td><span>Akcje spółek wzrostowych i dojrzałych<sup>1</sup></span></td>
          <td><CheckBox id='growthAndMaturedSharesYes' isChecked={surveyResults.sharesGrowingAndMature} /></td>
          <td><CheckBox id='growthAndMaturedSharesNo' isChecked={!surveyResults.sharesGrowingAndMature} /></td>
        </TableRow>
        <TableRow>
          <td><span>Akcje spółek typu start-up<sup>2</sup></span></td>
          <td><CheckBox id='startUpSharesYes' isChecked={surveyResults.startUps} /></td>
          <td><CheckBox id='startUpSharesNo' isChecked={!surveyResults.startUps} /></td>
        </TableRow>
        <TableRow>
          <td><span>Obligacje korporacyjne<sup>3</sup></span></td>
          <td><CheckBox id='corporateBondsYes' isChecked={surveyResults.corporateBonds} /></td>
          <td><CheckBox id='corporateBondsNo' isChecked={!surveyResults.corporateBonds} /></td>
        </TableRow>
        <TableRow>
          <td><span>Akcje spółek wzrostowych i dojrzałych ESG<sup>4</sup></span></td>
          <td><CheckBox id='growthAndMaturedSharesEsgYes' isChecked={surveyResults.sharesGrowingAndMatureESG} /></td>
          <td><CheckBox id='growthAndMaturedSharesEsgNo' isChecked={!surveyResults.sharesGrowingAndMatureESG} /></td>
        </TableRow>
        <TableRow>
          <td><span>Akcje spółek typu start-up ESG<sup>4</sup></span></td>
          <td><CheckBox id='startUpSharesEsgYes' isChecked={surveyResults.startUpsESG} /></td>
          <td><CheckBox id='startUpSharesEsgNo' isChecked={!surveyResults.startUpsESG} /></td>
        </TableRow>
        <TableRow>
          <td><span>Obligacje korporacyjne ESG<sup>4</sup></span></td>
          <td><CheckBox id='corporateBondsEsgYes' isChecked={surveyResults.corporateBondsESG} /></td>
          <td><CheckBox id='corporateBondsEsgNo' isChecked={!surveyResults.corporateBondsESG} /></td>
        </TableRow>
      </tbody >
    </Table>
  )
};

const Footnotes = () => {
  return (
    <div style={{ fontSize: '8.0pt' }}>
      <p style={{ margin: 0, textAlign: 'justify' }}>
        <sup>1</sup> Emitentami akcji spółek wzrostowych i dojrzałych są co do zasady podmioty, które:
      </p>
      <ol style={{ listStyle: 'lower-alpha', margin: '5px 0px' }}>
        <li>prowadzą działalność co najmniej przez 3 lata, niezależnie od formy prawnej działalności lub krócej niż 3
          lata, jeżeli na podstawie innych okoliczności można uznać spółkę za spełniającą wymóg dojrzałości
          organizacyjnej i biznesowej,</li>
        <li>generują powtarzalne przychody z prowadzonej działalności i co najmniej perspektywę generowania dodatniej
          rentowności prowadzonej działalności,</li>
        <li>zamierzają wprowadzić akcje do obrotu na rynku NewConnect w perspektywie około roku od dnia zakończenia
          emisji akcji lub są spółkami, których akcje są notowane na rynku zorganizowanym.</li>
      </ol>
      <p style={{ margin: 0, textAlign: 'justify' }}>
        <sup>2</sup> Emitentami akcji spółek typu start-up są co do zasady podmioty, które:
      </p>
      <ol style={{ listStyle: 'lower-alpha', margin: '5px 0px' }}>
        <li>prowadzą działalność przez okres krótszy niż 3 lata, niezależnie od formy prawnej działalności,</li>
        <li>nie osiągają regularnych przychodów z prowadzonej działalności lub nie generują zysków,</li>
        <li>zamierzają wprowadzić akcje do obrotu na rynku NewConnect w perspektywie około 3 lat od dnia zakończenia
          emisji akcji.</li>
      </ol>
      <p style={{ margin: 0, textAlign: 'justify' }}>
        <sup>3</sup> Emitentami obligacji korporacyjnych mają być podmioty, które posiadają stabilną pozycję finansową, regularne
        przychody z działalności oraz dodatnią rentowność.
      </p>
      <p style={{ margin: 0, textAlign: 'justify' }}>
        <sup>4</sup> Instrumenty finansowe ESG – instrumenty finansowe uwzględniające czynniki zrównoważonego rozwoju, tj. kwestie
        środowiskowe, społeczne i pracownicze, kwestie dotyczące poszanowania praw człowieka oraz przeciwdziałania
        korupcji i przekupstwu.
      </p>
    </div>
  );
}

export const SurveyTemplate = ({ questionsState, surveyResults }) => {

  const { clientInfo, questions } = questionsState;

  return (
    <body lang="PL" style={{ fontFamily: `'Segoe UI', 'sans-serif'`, fontSize: '9.5pt' }}>

      <p style={{ textAlign: 'right', fontSize: '8.5pt' }}><span>__________________________, dnia _____________________</span></p>

      <Title>Ocena odpowiedniości usługi maklerskiej w zakresie przyjmowania i przekazywania
        zleceń nabycia lub zbycia instrumentów finansowych (akcje i obligacje) na rynku pierwotnym i wtórnym</Title>
      <TextBlock>Dom Maklerski INC S.A. („Dom Maklerski”) zwraca się do Klienta lub potencjalnego Klienta o przedstawienie
        podstawowych informacji dotyczących poziomu wiedzy o inwestowaniu w zakresie instrumentów finansowych oraz
        doświadczenia inwestycyjnego.
      </TextBlock>
      <TextBlock>Informacje przedstawione przez Klienta zostaną wykorzystane wyłącznie dla celów dokonania oceny odpowiedniości
        usługi maklerskiej lub instrumentu finansowego, a następnie przypisania go do odpowiedniej grupy docelowej –
        pozytywnej lub negatywnej.
      </TextBlock>
      <TextBlock bold>Dom Maklerski rekomenduje i zachęca do udzielenia odpowiedzi na wszystkie
        przedstawione pytania. Brak odpowiedzi na wszystkie lub niektóre pytania ujęte w niniejszej ankiecie może spowodować brak
        możliwości oceny przez Dom Maklerski odpowiedniości usługi maklerskiej lub instrumentu finansowego dla Klienta oraz
        zakwalifikowanie Klienta do negatywnej grupy docelowej.</TextBlock>
      <TextBlock bold>Dom Maklerski stosuje strategię dystrybucji, zgodnie z którą nie oferuje
        instrumentów finansowych Klientom, którzy znajdują się w negatywnej grupie docelowej.</TextBlock>
      <TextBlock bold>Celem oceny odpowiedniości jest umożliwienie działania przez Dom Maklerski w najlepiej pojętym interesie Klienta.</TextBlock>

      <Table>
        <tbody>
          <ClientInfoSection clientInfo={clientInfo} />
          <SurveySectionHeader number={1}>Ankieta jest wypełniana w związku z zamiarem:</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={undefined}>Zawarcia umowy o świadczenie usług maklerskich polegających na przyjmowaniu i przekazywaniu
              zleceń nabycia lub zbycia instrumentów finansowych w odniesieniu do akcji oraz do obligacji</SurveyQuestion>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={2}>Proszę zaznaczyć odpowiedzi, które mają zastosowanie do Pana/Pani wykształcenia i doświadczenia zawodowego:</SurveySectionHeader>
          <TableRow>
            <SurveyAnswerHorizontal id='2_1' questions={questions} number="2.1">Pracuję od co najmniej 1 roku lub pracowałem/am w ciągu ostatnich 5 lat, przez okres co
              najmniej 1 roku, na stanowisku, które wymaga / wymagało bezpośredniego merytorycznego udziału
              w procesie podejmowania decyzji inwestycyjnych lub dokonywania innych transakcji w zakresie
              instrumentów finansowych</SurveyAnswerHorizontal>
            <SurveyAnswerHorizontal id='2_2' questions={questions} number="2.2">Posiadam wykształcenie wyższe specjalistyczne, związane ściśle z prowadzeniem
              inwestycji na rynku kapitałowym, lub niezależnie od wykształcenia, co
              najmniej jeden z następujących tytułów / certyfikatów: Maklera Papierów
              Wartościowych, Doradcy Inwestycyjnego, CFA<sup>®</sup>, FRM<sup>®</sup>, PRM<sup>®</sup></SurveyAnswerHorizontal>
            <SurveyAnswerHorizontal id='2_3' questions={questions} number="2.3">Żadna z odpowiedzi 2.1 - 2.2</SurveyAnswerHorizontal>
          </TableRow>
          <SurveySectionHeader number={3}>Test wiedzy</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.1'}>Usługa przyjmowania i przekazywania zleceń nabycia lub zbycia instrumentów finansowych to usługa, w której:</SurveyQuestion>
            <SurveyAnswer id='3_1_1' questions={questions}>Firma inwestycyjna wykonuje zlecenie Klienta</SurveyAnswer>
            <SurveyAnswer id='3_1_2' questions={questions}>Firma inwestycyjna pośredniczy w przekazaniu zlecenia Klienta do innego podmiotu (np. emitenta lub oferującego)</SurveyAnswer>
            <SurveyAnswer id='3_1_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.2'}>Czy cena akcji lub obligacji na giełdzie może spaść poniżej ich ceny emisyjnej?</SurveyQuestion>
            <SurveyAnswer id='3_2_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='3_2_2' questions={questions}>Nie</SurveyAnswer>
            <SurveyAnswer id='3_2_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.3'}>Prawdziwe twierdzenie to:</SurveyQuestion>
            <SurveyAnswer id='3_3_1' questions={questions}>Akcje i obligacje dają gwarancję zysku</SurveyAnswer>
            <SurveyAnswer id='3_3_2' questions={questions}>Osiąganie wyższych zysków z reguły wiąże się z większym ryzykiem</SurveyAnswer>
            <SurveyAnswer id='3_3_3' questions={questions}>Spółki, których instrumenty są notowane na giełdzie, nie mogą ogłosić upadłości</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.4'}>Kupując nienotowane akcje lub obligacje narażam się na ryzyko związane z ograniczeniem ich zbywalności
              do czasu wprowadzenia tych instrumentów finansowych do obrotu zorganizowanego (rynek główny GPW, ASO):</SurveyQuestion>
            <SurveyAnswer id='3_4_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='3_4_2' questions={questions}>Nie</SurveyAnswer>
            <SurveyAnswer id='3_4_3' questions={questions}>Nie wiem</SurveyAnswer>
            <p style={{ margin: '5.5pt' }}><span>&nbsp;</span></p>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.5'}>Czy w przypadku akcji i obligacji nienotowanych ich emitent <span style={{ fontWeight: 'bold' }}>ma obowiązek</span> publikacji informacji w formie raportów bieżących i okresowych?</SurveyQuestion>
            <SurveyAnswer id='3_5_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='3_5_2' questions={questions}>Nie</SurveyAnswer>
            <SurveyAnswer id='3_5_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.6'}>Czy akcje dają akcjonariuszowi prawo do odsetek?</SurveyQuestion>
            <SurveyAnswer id='3_6_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='3_6_2' questions={questions}>Nie</SurveyAnswer>
            <SurveyAnswer id='3_6_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.7'}>Ryzyko płynności w przypadku akcji polega na:</SurveyQuestion>
            <SurveyAnswer id='3_7_1' questions={questions}>Braku lub ograniczonej możliwości sprzedaży akcji</SurveyAnswer>
            <SurveyAnswer id='3_7_2' questions={questions}>Możliwości rozwodnienia akcjonariatu emitenta akcji, tj. zmniejszenia procentowego udziału poszczególnych posiadaczy akcji</SurveyAnswer>
            <SurveyAnswer id='3_7_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.8'}>Czy inwestycje w obligacje skarbowe wiążą się z takim samym ryzykiem, jak inwestycje w obligacje korporacyjne?</SurveyQuestion>
            <SurveyAnswer id='3_8_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='3_8_2' questions={questions}>Nie</SurveyAnswer>
            <SurveyAnswer id='3_8_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.9'}>Czy dywersyfikacja portfela inwestycyjnego eliminuje ryzyko poniesienia straty?</SurveyQuestion>
            <SurveyAnswer id='3_9_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='3_9_2' questions={questions}>Nie</SurveyAnswer>
            <SurveyAnswer id='3_9_3' questions={questions}>Nie wiem</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.10'}>Inwestując należy:</SurveyQuestion>
            <SurveyAnswer id='3_10_1' questions={questions}>Zainwestować całe posiadane środki w jeden instrument finansowy aby zmaksymalizować potencjalny zysk</SurveyAnswer>
            <SurveyAnswer id='3_10_2' questions={questions}>Dokonać dokładnej analizy dokumentów finansowych i korporacyjnych danego emitenta instrumentu finansowego</SurveyAnswer>
            <SurveyAnswer id='3_10_3' questions={questions}>Przeznaczać na nie maksymalnie dużo środków, w tym także te, które są potrzebne do codziennego życia (np. spłata kredytu hipotecznego)</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.11'}>Jaką maksymalną stratę można ponieść w związku z inwestycją w akcje lub obligacje korporacyjne pojedynczego emitenta?</SurveyQuestion>
            <SurveyAnswer id='3_11_1' questions={questions}>Nie większą niż 50% zainwestowanych środków</SurveyAnswer>
            <SurveyAnswer id='3_11_2' questions={questions}>o 100 % zainwestowanych środkówe</SurveyAnswer>
            <SurveyAnswer id='3_11_3' questions={questions}>Inwestując w akcje lub obligacje korporacyjne nie można ponieść straty</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'3.12'}>Zadłużenie emitenta akcji lub obligacji w sprawozdaniu finansowym przedstawia:</SurveyQuestion>
            <SurveyAnswer id='3_12_1' questions={questions}>Rachunek przepływów pieniężnych (cashflow)</SurveyAnswer>
            <SurveyAnswer id='3_12_2' questions={questions}>Rachunek zysków i strat</SurveyAnswer>
            <SurveyAnswer id='3_12_3' questions={questions}>Bilans</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={4}>Doświadczenie</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'4.1'}>Proszę podać szacunkową <span style={{ fontWeight: 'bold' }}>łączną wartość transakcji</span> zawartych w
              okresie <span style={{ fontWeight: 'bold' }}>ostatnich 36 miesięcy</span> w zakresie poniższych instrumentów
              finansowych (należy wybrać odpowiedź dla każdego z podanych instrumentów):</SurveyQuestion>
            <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
            <SurveyQuestion number={'4.1.1'} marginLeft="36.65pt" textIndent="-12.5pt">Akcje spółek publicznych (notowanych na rynku regulowanym lub ASO):</SurveyQuestion>
            <SurveyAnswer id='4_1_1_1' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>0 PLN</SurveyAnswer>
            <SurveyAnswer id='4_1_1_2' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Od 1 PLN do 10.000 PLN</SurveyAnswer>
            <SurveyAnswer id='4_1_1_3' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Od 10.000 PLN do 50.000</SurveyAnswer>
            <SurveyAnswer id='4_1_1_4' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Powyżej 50.000 PLN</SurveyAnswer>
            <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
            <SurveyQuestion number={'4.1.2'} marginLeft="36.65pt" textIndent="-12.5pt">Akcje spółek niepublicznych:</SurveyQuestion>
            <SurveyAnswer id='4_1_2_1' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>0 PLN</SurveyAnswer>
            <SurveyAnswer id='4_1_2_2' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Od 1 PLN do 10.000 PLN</SurveyAnswer>
            <SurveyAnswer id='4_1_2_3' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Od 10.000 PLN do 50.000</SurveyAnswer>
            <SurveyAnswer id='4_1_2_4' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Powyżej 50.000 PLN</SurveyAnswer>
            <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
            <SurveyQuestion number={'4.1.3'} marginLeft="36.65pt" textIndent="-12.5pt">Obligacje korporacyjne:</SurveyQuestion>
            <SurveyAnswer id='4_1_3_1' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>0 PLN</SurveyAnswer>
            <SurveyAnswer id='4_1_3_2' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Od 1 PLN do 10.000 PLN</SurveyAnswer>
            <SurveyAnswer id='4_1_3_3' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Od 10.000 PLN do 50.000</SurveyAnswer>
            <SurveyAnswer id='4_1_3_4' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Powyżej 50.000 PLN</SurveyAnswer>
            <p style={{ margin: '5.5pt' }}><span>&nbsp;</span></p>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'4.2'}>Proszę podać szacunkową <span style={{ fontWeight: 'bold' }}>liczbę transakcji</span> zawartych w okresie
              <span style={{ fontWeight: 'bold' }}> ostatnich 36 miesięcy</span> w zakresie poniższych instrumentów finansowych
              (należy wybrać odpowiedź dla każdego z podanych instrumentów):</SurveyQuestion>
            <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
            <SurveyQuestion number={'4.2.1'} marginLeft="36.65pt" textIndent="-12.5pt">Akcje spółek publicznych (notowanych na rynku regulowanym lub ASO):</SurveyQuestion>
            <SurveyAnswer id='4_2_1_1' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>0</SurveyAnswer>
            <SurveyAnswer id='4_2_1_2' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>1-2</SurveyAnswer>
            <SurveyAnswer id='4_2_1_3' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>3-10</SurveyAnswer>
            <SurveyAnswer id='4_2_1_4' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Powyżej 10</SurveyAnswer>
            <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
            <SurveyQuestion number={'4.2.2'} marginLeft="36.65pt" textIndent="-12.5pt">Akcje spółek niepublicznych:</SurveyQuestion>
            <SurveyAnswer id='4_2_2_1' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>0</SurveyAnswer>
            <SurveyAnswer id='4_2_2_2' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>1-2</SurveyAnswer>
            <SurveyAnswer id='4_2_2_3' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>3-10</SurveyAnswer>
            <SurveyAnswer id='4_2_2_4' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Powyżej 10</SurveyAnswer>
            <p style={{ margin: '0cm' }}><span>&nbsp;</span></p>
            <SurveyQuestion number={'4.2.3'} marginLeft="36.65pt" textIndent="-12.5pt">Obligacje korporacyjne:</SurveyQuestion>
            <SurveyAnswer id='4_2_3_1' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>0</SurveyAnswer>
            <SurveyAnswer id='4_2_3_2' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>1-2</SurveyAnswer>
            <SurveyAnswer id='4_2_3_3' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>3-10</SurveyAnswer>
            <SurveyAnswer id='4_2_3_4' questions={questions} marginLeft='49.65pt' textIndent='-10pt'>Powyżej 10</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'4.3'}>Proszę wskazać <span style={{ fontWeight: 'bold' }}>rodzaje instrumentów
              finansowych</span> posiadanych w okresie <span style={{ fontWeight: 'bold' }}>ostatnich 36 miesięcy</span>:</SurveyQuestion>
            <SurveyAnswer id='4_3_1' questions={questions}>Akcje</SurveyAnswer>
            <SurveyAnswer id='4_3_2' questions={questions}>Obligacje</SurveyAnswer>
            <SurveyAnswer id='4_3_3' questions={questions}>Żadne z powyższych</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={5}>Horyzont inwestycyjny</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'5'}>Proszę wskazać planowany okres inwestycji w akcje lub obligacje:</SurveyQuestion>
            <SurveyAnswer id='5_1' questions={questions}>Krótki – do 1 roku</SurveyAnswer>
            <SurveyAnswer id='5_2' questions={questions}>Średni – od 1 roku do 3 lat</SurveyAnswer>
            <SurveyAnswer id='5_3' questions={questions}>Długi – powyżej 3 lat</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={6}>Cel inwestycji</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'6'}>Proszę wskazać cel inwestycji (można zaznaczyć więcej niż 1 odpowiedź):</SurveyQuestion>
            <SurveyAnswer id='6_1' questions={questions}>Chcę zainwestować nadwyżkę środków finansowych (<span style={{ fontWeight: 'bold' }}>ochrona kapitału</span>)</SurveyAnswer>
            <SurveyAnswer id='6_2' questions={questions}>Chcę zainwestować wolne środki finansowe celem wypracowania <span style={{ fontWeight: 'bold' }}>stabilnego zysku</span> przewyższającego zysk z lokat bankowych przy umiarkowanym ryzyku inwestycyjnym</SurveyAnswer>
            <SurveyAnswer id='6_3' questions={questions}>Chcę zainwestować wolne środki finansowe celem osiągania <span style={{ fontWeight: 'bold' }}>wysokich stóp zwrotu</span> z inwestycji przy akceptacji podwyższonego ryzyka</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={7}>Cel inwestycyjny związany z czynnikami zrównoważonego rozwoju</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'7.1'}>Proszę wskazać, czy posiadasz cele inwestycyjne związane z czynnikami zrównoważonego rozwoju, tj. kwestiami
              środowiskowymi, społecznymi i pracowniczymi, kwestiami dotyczącymi poszanowania praw człowieka oraz
              przeciwdziałania korupcji i przekupstwu:</SurveyQuestion>
            <SurveyAnswer id='7_1_1' questions={questions}>Tak</SurveyAnswer>
            <SurveyAnswer id='7_1_2' questions={questions}>Nie (proszę pominąć pytanie 7.2.)</SurveyAnswer>
            <SurveyAnswer id='7_1_3' questions={questions}>Odmawiam odpowiedzi i akceptuję, że będzie to
              równoznaczne z nieposiadaniem celów inwestycyjnych związanych z czynnikami zrównoważonego rozwoju (proszę pominąć
              pytanie 7.2.)</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'7.2'}>Proszę wskazać, jakimi ofertami instrumentów finansowych jesteś zainteresowany/a:</SurveyQuestion>
            <SurveyAnswer id='7_2_1' questions={questions}>Jestem zainteresowany/a ofertami
              wszystkich instrumentów finansowych, tj. ofertami instrumentów finansowych uwzględniających czynniki
              zrównoważonego rozwoju oraz ofertami instrumentów finansowych nieuwzględniających czynników zrównoważonego
              rozwoju,</SurveyAnswer>
            <SurveyAnswer id='7_2_2' questions={questions}>Jestem zainteresowany/a ofertami wyłącznie
              instrumentów finansowych uwzględniających czynniki zrównoważonego rozwoju<sup>1</sup></SurveyAnswer>
            <SurveyAnswer id='7_2_3' questions={questions}>Odmawiam odpowiedzi</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={8}>Tolerancja ryzyka</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'8'}>Proszę określić stopień akceptacji przez Panią/Pana ryzyka inwestycyjnego:</SurveyQuestion>
            <SurveyAnswer id='8_1' questions={questions}>Niski – akceptuję wyłącznie ryzyko związane z inwestowaniem w
              <span style={{ fontWeight: 'bold' }}> lokaty bankowe</span></SurveyAnswer>
            <SurveyAnswer id='8_2' questions={questions}>Średni – akceptuję ryzyko związane
              z inwestowaniem w spółki <span style={{ fontWeight: 'bold' }}>publiczne</span> (notowane na rynku głównym GPW,
              NewConnect) lub w spółki <span style={{ fontWeight: 'bold' }}>zamierzające uzyskać status spółki
                publicznej</span> (notowanej na rynku głównym GPW, NewConnect) w terminie około 36 miesięcy po przeprowadzeniu
              oferty publicznej lub związane z inwestowaniem w obligacje korporacyjne emitowane przez takie spółki</SurveyAnswer>
            <SurveyAnswer id='8_3' questions={questions}>Wysoki – akceptuję ryzyko związane z inwestowaniem w <span style={{ fontWeight: 'bold' }}>
              znajdujące się na wczesnym etapie rozwoju</span>, które mogą nigdy nie uzyskać statusu spółki publicznej (notowanej na rynku
              głównym GPW, NewConnect) lub mogą tego dokonać w terminie najwcześniej 2-3 lat po przeprowadzeniu oferty publicznej lub związane
              z inwestowaniem w obligacje korporacyjne emitowane przez takie spółki</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={9}>Sytuacja finansowa</SurveySectionHeader>
          <SurveyQuestionWrapper>
            <SurveyQuestion number={'9'}>Proszę określić stopień akceptacji ryzyka inwestycyjnego:</SurveyQuestion>
            <SurveyAnswer id='9_1' questions={questions}>Nie dopuszczam możliwości poniesienia żadnej, nawet przejściowej straty z inwestycji</SurveyAnswer>
            <SurveyAnswer id='9_2' questions={questions}>Wycofam się z inwestycji dopiero w przypadku, gdy straty <span style={{ fontWeight: 'bold' }}>przekroczą 10%
            </span> wartości zainwestowanych środków</SurveyAnswer>
            <SurveyAnswer id='9_3' questions={questions}>Nie wycofam się z inwestycji nawet w przypadku, gdy straty <span style={{ fontWeight: 'bold' }}>przekroczą 10%
            </span> wartości zainwestowanych środków</SurveyAnswer>
          </SurveyQuestionWrapper>
          <SurveySectionHeader number={10}>Oświadczenie</SurveySectionHeader>
          <tr style={{ height: '17.0pt' }}>
            <td colSpan={12} >
              <p style={{ margin: '0', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span style={{ fontStyle: 'italic', textAlign: 'justify' }}>Potwierdzam rzetelność, kompletność i aktualność powyższych informacji.
                </span>
              </p>
              <p style={{ margin: '3.6pt' }}>
                <span style={{ fontStyle: 'italic', textAlign: 'justify' }}>Jednocześnie oświadczam, że Dom Maklerski
                  poinformował mnie, że w przypadku nieudzielenia przeze mnie odpowiedzi na niektóre lub wszystkie pytania,
                  Dom
                  Maklerski może nie być w stanie dokonać oceny w zakresie odpowiedniości
                  usług lub instrumentów finansowych oferowanych przez Dom Maklerski, a w
                  przypadku udzielenia nierzetelnych informacji, wynik dokonanej na ich
                  podstawie oceny może nie odzwierciedlać mojej rzeczywistej sytuacji, co w
                  konsekwencji może być sprzeczne z moim najlepiej pojętym interesem jako
                  klienta Domu Maklerskiego.
                </span>
              </p>
              <Siganture bold color='red' text='podpis Klienta' />
            </td>
          </tr >
          <SurveySectionHeader number={11}>Ocena Domu Maklerskiego</SurveySectionHeader>
          <tr style={{ height: '17.0pt' }}>
            <td colSpan={12} style={{ borderBottom: 0 }}>
              <p style={{ margin: '0', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span>Na podstawie powyższych informacji Dom Maklerski stwierdza, że w jego ocenie usługa przyjmowania i
                  przekazywania zleceń nabycia lub zbycia instrumentów finansowych jest dla Klienta:</span>
              </p>
              <p style={{ margin: '0', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '0', marginBottom: '15px', fontSize: '4.0pt' }}><span>&nbsp;</span></p>
              <table border={1} cellSpacing={0} cellPadding={0}
                style={{ width: '100%', borderCollapse: 'collapse', border: 'solid black 1.0pt', fontSize: '8.5pt', margin: '0 auto', textAlign: 'center' }}>
                <tr style={{ height: '17.0pt' }}>
                  <td><span style={{ fontWeight: 'bold' }}>Instrument finansowy</span></td>
                  <td><span style={{ fontWeight: 'bold' }}>Usługa odpowiednia</span></td>
                  <td><span style={{ fontWeight: 'bold' }}>Usługa nieodpowiednia</span></td>
                </tr>
                <tr style={{ height: '17.0pt' }}>
                  <td>akcje</td>
                  <td><span><CheckBox id='sharesServiceIsValid' isChecked={surveyResults.shares} /></span></td>
                  <td><span><CheckBox id='sharesServiceIsInvalid' isChecked={!surveyResults.shares} /></span></td>
                </tr>
                <tr style={{ height: '17.0pt' }}>
                  <td>obligacje</td>
                  <td><span><CheckBox id='bondsServiceIsValid' isChecked={surveyResults.sbonds} /></span></td>
                  <td><span><CheckBox id='bondsServiceIsInvalid' isChecked={!surveyResults.bonds} /></span></td>
                </tr>
              </table>
            </td >
          </tr >
          <tr style={{ height: '17.0pt' }} id='invalidReasonSection'>
            <td colSpan={12} style={{ borderBottom: 0, borderTop: 0 }}>
              <p style={{ margin: '0', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span>Nieodpowiedniość została stwierdzona ze względu na:</span>
              </p>
              <p style={{ margin: 0, marginLeft: '36.65pt', marginRight: '3.6pt', textAlign: 'justify', textIndent: '-12.5pt' }}>
                <span><CheckBox id='reasonKnowledgeAndExperience' isChecked={(!surveyResults.sharesKnowledge || !surveyResults.bondsKnowledge)} /> Niedostateczną
                  wiedzę i doświadczenie</span>
              </p>
              <p style={{ margin: 0, marginLeft: '36.65pt', marginRight: '3.6pt', textAlign: 'justify', textIndent: '-12.5pt' }}>
                <span><CheckBox id='reasonPeriodGoalRiskStandings' isChecked={(!surveyResults.sharesHorizon || !surveyResults.bondsHorizon)} /> Nieodpowiedni
                  horyzont inwestycyjny, cel inwestycji, tolerancję ryzyka lub sytuację finansową</span>
              </p>
            </td>
          </tr >
          <tr style={{ height: '17.0pt' }}>
            <td colSpan={12} style={{ borderTop: 0 }}>
              <Siganture bold color='red' text='podpis Klienta' />
            </td>
          </tr >
          <tr style={{ height: '17.0pt', backgroundColor: '#F2F2F2' }} id='notAllAnsweredInfo1'>
            <td colSpan={12} >
              <p style={{ margin: '0', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span style={{ fontStyle: 'italic' }}>Jestem świadomy, że w wyniku oceny odpowiedniości usługi maklerskiej lub
                  instrumentu finansowego oferowany przez Dom Maklerski instrument finansowy lub usługa maklerska <span
                    style={{ textDecoration: 'underline' }}>mogą być dla mnie nieodpowiednie i wyrażam wolę zawarcia umowy z Domem
                    Maklerskim</span>, a tym samym akceptuję znaczące ryzyko.</span>
              </p>
              <p style={{ margin: '12.5pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span>_______________________________________                                                                _______________________________________</span>
              </p>
              <p style={{ margin: '3.6pt' }}>
                <span style={{ color: 'red', fontWeight: 'bold' }}>podpis
                  Klienta                                                                                                  </span>
                <span>podpis przedstawiciela Domu Maklerskiego</span>
              </p>
            </td>
          </tr >
          {!surveyResults.allAnswered && <tr style={{ height: '17.0pt', backgroundColor: '#F2F2F2' }}>
            <td colSpan={12} >
              <p style={{ margin: '0', fontSize: '2.0pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Z uwagi na nieudzielenie przez Klienta odpowiedzi
                  na wszystkie lub niektóre pytania</span>
                <span style={{ fontStyle: 'italic' }}> przedstawione w ww. ankiecie, Dom Maklerski informuje Klienta, że
                  skutkuje to brakiem możliwości przeprowadzenia przez Dom Maklerski oceny odpowiedniości usługi maklerskiej
                  lub
                  instrumentu finansowego dla Klienta, a tym samym Klient akceptuje znaczące ryzyko, że oferowany przez Dom
                  Maklerski instrument finansowy lub usługa maklerska mogą być dla Klienta nieodpowiednie. Klient
                  oświadcza, że <span style={{ textDecoration: 'underline' }}>wyraża wolę zawarcia umowy z Domem
                    Maklerskim.</span></span>
              </p>
              <p style={{ margin: '12.5pt' }}><span>&nbsp;</span></p>
              <p style={{ margin: '3.6pt' }}>
                <span>_______________________________________                                                                _______________________________________</span>
              </p>
              <p style={{ margin: '3.6pt' }}>
                <span style={{ color: 'red', fontWeight: 'bold' }}>podpis
                  Klienta                                                                                                  </span>
                <span>podpis przedstawiciela Domu Maklerskiego</span>
              </p>
            </td>
          </tr >}
        </tbody>
      </Table>

      <br style={{ pageBreakAfter: 'always' }} />

      <Title>INFORMACJA O GRUPIE DOCELOWEJ</Title>

      <TextBlock>Na podstawie przeprowadzonej oceny odpowiedniości usługi maklerskiej w zakresie przyjmowania i przekazywania
        zleceń nabycia lub zbycia instrumentów finansowych (akcje i obligacje) na rynku pierwotnym i wtórnym, Dom
        Maklerski INC S.A. informuje o zaklasyfikowaniu Klienta do następujących grup docelowych:</TextBlock>

      <ClientClassTable surveyResults={surveyResults}/>

      <TextBlock>Dom Maklerski INC S.A. nie oferuje instrumentów finansowych Klientom, którzy znajdują się w negatywnej grupie
        docelowej określonej dla danego instrumentu finansowego (akcji lub obligacji), w związku z powyższym Klient
        znajdujący się w negatywnej grupie docelowej dla danego instrumentu finansowego nie będzie otrzymywał informacji o
        ofertach tego instrumentu finansowego przeprowadzanych przez Dom Maklerski INC S.A. oraz nie będzie mógł składać
        zapisów w tych ofertach.</TextBlock>

      <p style={{ margin: 0 }}><span>&nbsp;</span></p>

      <Footnotes />
    </body >
  )
};