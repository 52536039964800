import { saveAs } from 'file-saver';
import SurveyBody from "./SurveyBody";
import { ACTIONS, getSurveyResults } from "../reducers/QuestionsReducer";
import TextBlock from "./TextBlock";
import { SurveyTemplate } from './SurveyTemplate.jsx';
import ReactDOMServer from 'react-dom/server';
import ButtonsWrapper from './ButtonsWrapper';
import Button from './Button';
import { useState } from 'react';

export default function Results({ section, questionsState, questionsDispatch = () => { } }) {

	const { questions } = questionsState;
	const surveyResults = getSurveyResults(questions);
	const [downloading, setDownloading] = useState(false);

	const tableStyle = { width: '100%', borderCollapse: 'collapse', margin: '30px 0' };
	const rowStyles = { border: '1px solid #e0e0e0', padding: '5px 10px' }

	const handleGeneratePdf = (e) => {
		setDownloading(true);
		const converterURL = 'https://investsharkserver.tymon101.usermd.net/FileConverterServer/api/convert/getPdfFromHtml';

		if (!window.XMLHttpRequest) return;

		let htmlSurvey = '<!DOCTYPE HTML>\n' + ReactDOMServer.renderToString(<SurveyTemplate questionsState={questionsState} surveyResults={surveyResults} />);

		var data = new FormData();
		data.append('file', htmlSurvey);

		var xhrPost = new XMLHttpRequest();
		xhrPost.open('POST', converterURL, true);
		xhrPost.responseType = "blob";
		xhrPost.onload = function () {
			setDownloading(false);
			saveAs(xhrPost.response, 'Ankieta MFID.pdf');
		};
		xhrPost.onerror = function () {
			setDownloading(false);
		};
		xhrPost.send(data);
	};

	return (
		<SurveyBody>
			<TextBlock>Na podstawie udzielonych informacji Dom Maklerski stwierdza, że w jego ocenie usługa przyjmowania i przekazywania zleceń nabycia lub zbycia instrumentów finansowych jest dla Klienta:</TextBlock>
			<table style={tableStyle}>
				<tbody>
					<tr>
						<th style={rowStyles}>Instrument finansowy</th>
						<th style={rowStyles}>Usługa odpowiednia</th>
						<th style={rowStyles}>Usługa nieodpowiednia</th>
					</tr>
					<tr>
						<td style={rowStyles}>akcje</td>
						<td style={rowStyles}><span>{surveyResults.shares ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.shares ? '☐' : '☑'}</span></td>
					</tr>
					<tr>
						<td style={rowStyles}>obligacje</td>
						<td style={rowStyles}><span>{surveyResults.bonds ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.bonds ? '☐' : '☑'}</span></td>
					</tr>
				</tbody>
			</table>
			<TextBlock>Nieodpowiedniość została stwierdzona ze względu na:</TextBlock>
			<div>
				<span>{(!surveyResults.sharesKnowledge || !surveyResults.bondsKnowledge) ? '☑' : '☐'}</span>
				<span>Niedostateczną wiedzę i doświadczenie</span>
			</div>
			<div>
				<span>{(!surveyResults.sharesHorizon || !surveyResults.bondsHorizon) ? '☑' : '☐'}</span>
				<span>Nieodpowiedni horyzont inwestycyjny, cel inwestycji, tolerancję ryzyka lub sytuację finansową</span>
			</div>
			<h4>INFORMACJA O GRUPIE DOCELOWEJ</h4>
			<TextBlock>Na podstawie przeprowadzonej oceny odpowiedniości usługi maklerskiej w zakresie przyjmowania i przekazywania zleceń nabycia lub zbycia instrumentów finansowych (akcje i obligacje) na rynku pierwotnym i wtórnym, Dom Maklerski INC S.A. informuje o zaklasyfikowaniu Klienta do następujących grup docelowych:</TextBlock>
			<table style={tableStyle}>
				<tbody>
					<tr>
						<th style={rowStyles} rowSpan={2}>
							<p>Nazwa grupy docelowej</p>
						</th>
						<th style={rowStyles} colSpan={2}>
							<p>Klasyfikacja Klienta</p>
						</th>
					</tr>
					<tr>
						<th style={rowStyles}>
							<p>Pozytywna grupa docelowa</p>
						</th>
						<th style={rowStyles}>
							<p>Negatywna grupa docelowa</p>
						</th>
					</tr>
					<tr>
						<td style={rowStyles}><span>Akcje spółek wzrostowych i dojrzałych<sup>1</sup></span></td>
						<td style={rowStyles}><span>{surveyResults.sharesGrowingAndMature ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.sharesGrowingAndMature ? '☐' : '☑'}</span></td>
					</tr>
					<tr>
						<td style={rowStyles}><span>Akcje spółek typu start-up<sup>2</sup></span></td>
						<td style={rowStyles}><span>{surveyResults.startUps ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.startUps ? '☐' : '☑'}</span></td>
					</tr>
					<tr>
						<td style={rowStyles}><span>Obligacje korporacyjne<sup>3</sup></span></td>
						<td style={rowStyles}><span>{surveyResults.corporateBonds ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.corporateBonds ? '☐' : '☑'}</span></td>
					</tr>
					<tr>
						<td style={rowStyles}><span>Akcje spółek wzrostowych i dojrzałych ESG<sup>4</sup></span></td>
						<td style={rowStyles}><span>{surveyResults.sharesGrowingAndMatureESG ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.sharesGrowingAndMatureESG ? '☐' : '☑'}</span></td>
					</tr>
					<tr>
						<td style={rowStyles}><span>Akcje spółek typu start-up ESG<sup>4</sup></span></td>
						<td style={rowStyles}><span>{surveyResults.startUpsESG ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.startUpsESG ? '☐' : '☑'}</span></td>
					</tr>
					<tr>
						<td style={rowStyles}><span>Obligacje korporacyjne ESG<sup>4</sup></span></td>
						<td style={rowStyles}><span>{surveyResults.corporateBondsESG ? '☑' : '☐'}</span></td>
						<td style={rowStyles}><span>{surveyResults.corporateBondsESG ? '☐' : '☑'}</span></td>
					</tr>
				</tbody>
			</table >
			<TextBlock>Dom Maklerski INC S.A. nie oferuje instrumentów finansowych Klientom, którzy znajdują się w negatywnej grupie docelowej określonej dla danego instrumentu finansowego (akcji lub obligacji), w związku z powyższym Klient znajdujący się w negatywnej grupie docelowej dla danego instrumentu finansowego nie będzie otrzymywał informacji o ofertach tego instrumentu finansowego przeprowadzanych przez Dom Maklerski INC S.A. oraz nie będzie mógł składać zapisów w tych ofertach.</TextBlock>
			<p><sup>{"1)"}</sup> Emitentami akcji spółek wzrostowych i dojrzałych są co do zasady podmioty, które:</p>
			<ol style={{listStyle: 'lower-alpha'}}>
				<li>prowadzą działalność co najmniej przez 3 lata, niezależnie od formy prawnej działalności lub krócej niż 3 lata, jeżeli na podstawie innych okoliczności można uznać spółkę za spełniającą wymóg dojrzałości organizacyjnej i biznesowej,</li>
				<li>generują powtarzalne przychody z prowadzonej działalności i co najmniej perspektywę generowania dodatniej rentowności prowadzonej działalności,</li>
				<li>zamierzają wprowadzić akcje do obrotu na rynku NewConnect w perspektywie około roku od dnia zakończenia emisji akcji lub są spółkami, których akcje są notowane na rynku zorganizowanym.</li>
			</ol>
			<p><sup>{"2)"}</sup> Emitentami akcji spółek typu start-up są co do zasady podmioty, które:</p>
			<ol style={{listStyle: 'lower-alpha'}}>
				<li>prowadzą działalność przez okres krótszy niż 3 lata, niezależnie od formy prawnej działalności,</li>
				<li>nie osiągają regularnych przychodów z prowadzonej działalności lub nie generują zysków,</li>
				<li>zamierzają wprowadzić akcje do obrotu na rynku NewConnect w perspektywie około 3 lat od dnia zakończenia emisji akcji.</li>
			</ol>
			<p><sup>{"3)"}</sup> Emitentami obligacji korporacyjnych mają być podmioty, które posiadają stabilną pozycję finansową, regularne przychody z działalności oraz dodatnią rentowność.
			</p>
			<p><sup>{"4)"}</sup> Instrumenty finansowe ESG - instrumenty finansowe uwzględniające czynniki zrównoważonego rozwoju, tj. kwestie środowiskowe, społeczne i pracownicze, kwestie dotyczące poszanowania praw człowieka oraz przeciwdziałania korupcji i przekupstwu.</p>
			<ButtonsWrapper>
				{section.previous && <Button color={'#bbbbbb'} onClick={() => questionsDispatch({ type: ACTIONS.CHANGE_ACTIVE_SECTION, payload: { activeSectionId: section.previous } })}>Wstecz</Button>}
				<Button disabled={downloading} onClick={handleGeneratePdf}>{downloading ? 'Trwa przetwarzanie' : 'Pobierz wyniki ankiety'}</Button>
			</ButtonsWrapper>
		</SurveyBody >
	);
}