import NavItem from "./NavItem"
import styles from './NavList.module.css';

export default function NavList({ questionsState, questionsDispatch = () => { } }) {

    return (
        <ul className={styles['nav-list']}>
            {questionsState.sections.map((section, index) => {
                return (
                    <NavItem key={section.id} section={section} number={index + 1} questionsDispatch={questionsDispatch} />
                )
            })}
        </ul>
    )
}