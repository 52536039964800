import ErrorBoundary from './components/ErrorBoundary';
import { Message } from './components/Message';

export default function AppError({ children }) {

  return (
    <ErrorBoundary fallback={<Message message={"Przepraszamy, wystąpił problem z ankietą"} />}>
      {children}
    </ErrorBoundary>
  );
}
