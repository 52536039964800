export const sections = [
    {
        "id": 1,
        "header": "Dane osobowe",
        "active": true,
        "questionsGroup": [
            {
                "id": "1",
                "label": null,
                "questions": []
            }
        ],
        "isFirst": true,
        "previous": null,
        "next": 2
    },
    {
        "id": 2,
        "header": "Wykształcenie i doświadczenie zawodowe",
        "questionsGroup": [
            {
                "id": "2",
                "label": null,
                "questions": ["2"]
            }
        ],
        "previous": 1,
        "next": 3
    },
    {
        "id": 3,
        "header": "Test wiedzy",
        "questionsGroup": [
            {
                "id": "3",
                "label": null,
                "questions": ["3_1", "3_2", "3_3", "3_4", "3_5", "3_6", "3_7", "3_8", "3_9", "3_10", "3_11", "3_12"]
            }
        ],
        "previous": 2,
        "next": 4
    },
    {
        "id": 4,
        "header": "Doświadczenie",
        "questionsGroup": [
            {
                "id": "4_1",
                "label": "4.1 Proszę podać szacunkową łączną wartość transakcji zawartych w okresie ostatnich 36 miesięcy w zakresie poniższych instrumentów finansowych (należy wybrać odpowiedź dla każdego z podanych instrumentów):",
                "questions": ["4_1_1", "4_1_2", "4_1_3"]
            },
            {
                "id": "4_2",
                "label": "4.2 Proszę podać szacunkową liczbę transakcji zawartych w okresie ostatnich 36 miesięcy w zakresie poniższych instrumentów finansowych (należy wybrać odpowiedź dla każdego z podanych instrumentów):",
                "questions": ["4_2_1", "4_2_2", "4_2_3"]
            },
            {
                "id": "4_3",
                "label": null,
                "questions": ["4_3"]
            }
        ],
        "previous": 3,
        "next": 5
    },
    {
        "id": 5,
        "header": "Horyzont inwestycyjny",
        "questionsGroup": [
            {
                "id": "5",
                "label": null,
                "questions": ["5"]
            }
        ],
        "previous": 4,
        "next": 6
    },
    {
        "id": 6,
        "header": "Cel inwestycji",
        "questionsGroup": [
            {
                "id": "6",
                "label": null,
                "questions": ["6"]
            }
        ],
        "previous": 5,
        "next": 7
    },
    {
        "id": 7,
        "header": "Cel inwestycyjny związany z czynnikami zrównoważonego rozwoju",
        "questionsGroup": [
            {
                "id": "7",
                "label": null,
                "questions": ["7_1", "7_2"]
            }
        ],
        "previous": 6,
        "next": 8
    },
    {
        "id": 8,
        "header": "Tolerancja ryzyka",
        "questionsGroup": [
            {
                "id": "7",
                "label": null,
                "questions": ["8"]
            }
        ],
        "previous": 7,
        "next": 9
    },
    {
        "id": 9,
        "header": "Sytuacja finansowa",
        "questionsGroup": [
            {
                "id": "8",
                "label": null,
                "questions": ["9"]
            }
        ],
        "previous": 8,
        "next": 10
    },
    {
        "id": 10,
        "header": "Wyniki",
        "isFinished": true,
        "questionsGroup": [
            {
                "id": "9",
                "label": null,
                "questions": []
            }
        ],
        "previous": 9,
        "next": null
    }
]