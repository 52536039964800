import { ACTIONS } from "../../reducers/QuestionsReducer";
import styles from './Answer.module.css';

export default function Answers({ question, questionsDispatch = () => { } }) {

    const { id, answers, multipleAnswers, required, disabled } = question;

    if (multipleAnswers) {
        return (
            <div className={styles['answers-wrapper']}>
                {answers.map((answer) => {
                    return (
                        <div key={answer.id} className={styles['answer']}>
                            <input
                                id={answer.id}
                                type={"checkbox"}
                                disabled={disabled}
                                checked={answer.checked}
                                required={required}
                                onChange={() => questionsDispatch({ type: ACTIONS.SET_CHECKBOX_ANSWER, payload: { questionId: question.id, answer: answer } })}
                            />
                            <label htmlFor={answer.id}>{answer.label}{answer.ref && <sup>{answer.ref}</sup>}</label>
                        </div>
                    )
                })}
            </div>
        );
    } else {
        return (
            <div className={styles['answers-wrapper']}>
                {answers.map((answer) => {
                    return (
                        <div key={answer.id} className={styles['answer']}>
                            <input
                                id={answer.id}
                                type={"radio"}
                                name={id}
                                disabled={disabled}
                                checked={answer.checked}
                                required={required}
                                onChange={() => questionsDispatch({ type: ACTIONS.SET_RADIO_ANSWER, payload: { questionId: question.id, answer: answer } })}
                            />
                            <label htmlFor={answer.id}>{answer.label}{answer.ref && <sup>{answer.ref}</sup>}</label>
                        </div>
                    )
                })}
            </div>
        );
    }
}