import './App.css';
import Question from './components/question/Question';
import { ACTIONS, INITIAL_STATE, questionsReducer } from './reducers/QuestionsReducer';
import { useReducer } from 'react';
import AppError from './AppError';
import NavList from './components/navigation/NavList';
import SurveyBody from './components/SurveyBody';
import QuestionGroupLabel from './components/question/QuestionGroupLabel';
import Results from './components/Results';
import ButtonsWrapper from './components/ButtonsWrapper';
import Button from './components/Button';
import FirstSection from './components/FirstSection';
import SurveyContainer from './components/SurveyContainer';
import SurveyWrapper from './components/SurveyWrapper';

function App() {

  const [questionsState, questionsDispatch] = useReducer(questionsReducer, INITIAL_STATE);

  return (
    <AppError>
      <SurveyWrapper>
        <SurveyContainer>
          <NavList questionsState={questionsState} questionsDispatch={questionsDispatch} />
          {questionsState.sections
            .filter(section => section.active)
            .map(section => {
              if (section.isFirst) {
                return (
                  <FirstSection key={section.id} section={section} questionsState={questionsState} questionsDispatch={questionsDispatch} />
                )
              }

              if (section.isFinished) {
                return (
                  <Results key={section.id} section={section} questionsState={questionsState} questionsDispatch={questionsDispatch} />
                )
              }

              return (
                <SurveyBody key={section.id} onSubmit={() => { questionsDispatch({ type: ACTIONS.CHANGE_ACTIVE_SECTION, payload: { activeSectionId: section.next } }); window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); }}>
                  {section.questionsGroup.map(group => {
                    return (
                      <div key={group.id}>
                        <QuestionGroupLabel>{group.label}</QuestionGroupLabel>
                        {group.questions.map(questionId => {
                          return (
                            <Question key={questionId} indent={group.label !== null} question={questionsState.questions.find(g => g.id === questionId)} questionsDispatch={questionsDispatch} />
                          )
                        })}
                      </div>
                    )
                  })}
                  <ButtonsWrapper>
                    {section.previous && <Button color={'#bbbbbb'} onClick={() => { questionsDispatch({ type: ACTIONS.CHANGE_ACTIVE_SECTION, payload: { activeSectionId: section.previous } }); window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); }}>Wstecz</Button>}
                    {section.next && <Button type="submit">Dalej</Button>}
                  </ButtonsWrapper>
                </SurveyBody>
              )
            })}
        </SurveyContainer>
      </SurveyWrapper>
    </AppError>
  );
}

export default App;
