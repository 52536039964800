import SurveyBody from "./SurveyBody";
import { ACTIONS } from "../reducers/QuestionsReducer";
import TextBlock from "./TextBlock";
import ButtonsWrapper from './ButtonsWrapper';
import Button from './Button';
import TextInput from "./TextInput";

export default function FirstSection({ section, questionsState, questionsDispatch = () => { } }) {

	const { clientInfo } = questionsState;

	return (
		<SurveyBody>
			<TextBlock bold>Ocena odpowiedniości usługi maklerskiej w zakresie przyjmowania i przekazywania zleceń nabycia lub zbycia instrumentów finansowych (akcje i obligacje) na rynku pierwotnym i wtórnym</TextBlock>
			<TextBlock>Dom Maklerski INC S.A. („Dom Maklerski”) zwraca się do Klienta lub potencjalnego Klienta o przedstawienie podstawowych informacji dotyczących poziomu wiedzy o inwestowaniu w zakresie instrumentów finansowych oraz doświadczenia inwestycyjnego.</TextBlock>
			<TextBlock>Informacje przedstawione przez Klienta zostaną wykorzystane wyłącznie dla celów dokonania oceny odpowiedniości usługi maklerskiej lub instrumentu finansowego, a następnie przypisania go do odpowiedniej grupy docelowej – pozytywnej lub negatywnej.</TextBlock>
			<TextBlock bold>Dom Maklerski rekomenduje i zachęca do udzielenia odpowiedzi na wszystkie przedstawione pytania. Brak odpowiedzi na wszystkie lub niektóre pytania ujęte w niniejszej ankiecie może spowodować brak możliwości oceny przez Dom Maklerski odpowiedniości usługi maklerskiej lub instrumentu finansowego dla Klienta oraz zakwalifikowanie Klienta do negatywnej grupy docelowej.</TextBlock>
			<TextBlock bold>Dom Maklerski stosuje strategię dystrybucji, zgodnie z którą nie oferuje instrumentów finansowych Klientom, którzy znajdują się w negatywnej grupie docelowej.</TextBlock>
			<TextBlock>Celem oceny odpowiedniości jest umożliwienie działania przez Dom Maklerski w najlepiej pojętym interesie Klienta.</TextBlock>

			<TextInput id={'first-name'} autoCompleteValue={'given-name'} label={'Imię/imiona'} placeholder={'Imię/imiona'} value={clientInfo.firstName} onChange={(e) => questionsDispatch({ type: ACTIONS.SET_FIRST_NAME, payload: { firstName: e.target.value } })} />
			<TextInput id={'last-name'} autoCompleteValue={'family-name'} label={'Nazwisko'} placeholder={'Nazwisko'} value={clientInfo.lastName} onChange={(e) => questionsDispatch({ type: ACTIONS.SET_LAST_NAME, payload: { lastName: e.target.value } })} />
			<TextInput id={'pesel'} label={'PESEL'} placeholder={'Twój PESEL'} value={clientInfo.pesel} onChange={(e) => questionsDispatch({ type: ACTIONS.SET_PESEL, payload: { pesel: e.target.value } })} />

			<ButtonsWrapper>
				{section.previous && <Button color={'#bbbbbb'} onClick={() => questionsDispatch({ type: ACTIONS.CHANGE_ACTIVE_SECTION, payload: { activeSectionId: section.previous } })}>Wstecz</Button>}
				{section.next && <Button onClick={() => questionsDispatch({ type: ACTIONS.CHANGE_ACTIVE_SECTION, payload: { activeSectionId: section.next } })}>Dalej</Button>}
			</ButtonsWrapper>
		</SurveyBody >
	);
}