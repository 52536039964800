import Answers from "./Answers";
import styes from './Question.module.css';

export default function Question({ question, indent = false, questionsDispatch = () => { } }) {

    if (!question) {
        return null;
    }

    return (
        <div style={indent ? {marginLeft: '48px'} : {}}>
            <div className={styes['question']}>{question.label}</div>
            <Answers question={question} questionsDispatch={questionsDispatch} />
            {question.refs && question.refs.map(ref => {
                return (
                    <div key={ref.id}><sup>{ref.id}</sup> {ref.text}</div>
                )
            })}
        </div>
    );
}