export const questions = [
    {
        id: "2",
        label: "2. Proszę zaznaczyć odpowiedzi, które mają zastosowanie do posiadanego wykształcenia i doświadczenia zawodowego",
        answers: [
            {
                id: "2_1",
                label: "2.1 Pracuję od co najmniej 1 roku lub pracowałem/am w ciągu ostatnich 5 lat, przez okres co najmniej 1 roku, na stanowisku, które wymaga / wymagało bezpośredniego merytorycznego udziału w procesie podejmowania decyzji inwestycyjnych lub dokonywania innych transakcji w zakresie instrumentów finansowych",
                points: 1,
                checked: false,
                disableQuestion: []
            },
            {
                id: "2_2",
                label: "2.2 Posiadam wykształcenie wyższe specjalistyczne, związane ściśle z prowadzeniem inwestycji na rynku kapitałowym, lub niezależnie od wykształcenia, co najmniej jeden z następujących tytułów / certyfikatów: Maklera Papierów Wartościowych, Doradcy Inwestycyjnego, CFA®, FRM®, PRM®",
                points: 2,
                checked: false,
                disableQuestion: []
            },
            {
                id: "2_3",
                label: "2.3 Żadna z odpowiedzi od 2.1. do 2.2.",
                points: 0,
                checked: false,
                disableQuestion: [],
                uncheckOthers: true
            }
        ],
        multipleAnswers: true,
        required: true,
        disabled: false
    },
    {
        id: "3_1",
        label: "3.1 Usługa przyjmowania i przekazywania zleceń nabycia lub zbycia instrumentów finansowych to usługa, w której:",
        answers: [
            {
                id: "3_1_1",
                label: "Firma inwestycyjna wykonuje zlecenie Klienta",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_1_2",
                label: "Firma inwestycyjna pośredniczy w przekazaniu zlecenia Klienta do innego podmiotu (np. emitenta lub oferującego)",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_1_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_2",
        label: "3.2 Czy cena akcji lub obligacji na giełdzie może spaść poniżej ich ceny emisyjnej?",
        answers: [
            {
                id: "3_2_1",
                label: "Tak",
                points: 1,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_2_2",
                label: "Nie",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_2_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_3",
        label: "3.3 Prawdziwe twierdzenie to:",
        answers: [
            {
                id: "3_3_1",
                label: "Akcje i obligacje dają gwarancję zysku",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_3_2",
                label: "Osiąganie wyższych zysków z reguły wiąże się z większym ryzykiem",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_3_3",
                label: "Spółki, których instrumenty są notowane na giełdzie, nie mogą ogłosić upadłości",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_4",
        label: "3.4 Kupując nienotowane akcje lub obligacje narażam się na ryzyko związane z ograniczeniem ich zbywalności do czasu wprowadzenia tych instrumentów finansowych do obrotu zorganizowanego (rynek główny GPW, ASO):",
        answers: [
            {
                id: "3_4_1",
                label: "Tak",
                points: 1,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_4_2",
                label: "Nie",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_4_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_5",
        label: "3.5 Czy w przypadku akcji i obligacji nienotowanych ich emitent ma obowiązek publikacji informacji w formie raportów bieżących i okresowych?",
        answers: [
            {
                id: "3_5_1",
                label: "Tak",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_5_2",
                label: "Nie",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_5_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_6",
        label: "3.6 Czy akcje dają akcjonariuszowi prawo do odsetek?",
        answers: [
            {
                id: "3_6_1",
                label: "Tak",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_6_2",
                label: "Nie",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_6_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_7",
        label: "3.7 Ryzyko płynności w przypadku akcji polega na:",
        answers: [
            {
                id: "3_7_1",
                label: "Braku lub ograniczonej możliwości sprzedaży akcji",
                points: 1,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_7_2",
                label: "Możliwości rozwodnienia akcjonariatu emitenta akcji, tj. zmniejszenia procentowego udziału poszczególnych posiadaczy akcji",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_7_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_8",
        label: "3.8 Czy inwestycje w obligacje skarbowe wiążą się z takim samym ryzykiem, jak inwestycje w obligacje korporacyjne?",
        answers: [
            {
                id: "3_8_1",
                label: "Tak",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_8_2",
                label: "Nie",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_8_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_9",
        label: "3.9 Czy dywersyfikacja portfela inwestycyjnego eliminuje ryzyko poniesienia straty?",
        answers: [
            {
                id: "3_9_1",
                label: "Tak",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_9_2",
                label: "Nie",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_9_3",
                label: "Nie wiem",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_10",
        label: "3.10 Inwestując należy:",
        answers: [
            {
                id: "3_10_1",
                label: "Zainwestować całe posiadane środki w jeden instrument finansowy aby zmaksymalizować potencjalny zysk",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_10_2",
                label: "Dokonać dokładnej analizy dokumentów finansowych i korporacyjnych danego emitenta instrumentu finansowego",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_10_3",
                label: "Przeznaczać na nie maksymalnie dużo środków, w tym także te, które są potrzebne do codziennego życia (np. spłata kredytu hipotecznego)",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_11",
        label: "3.11 Jaką maksymalną stratę można ponieść w związku z inwestycją w akcje lub obligacje korporacyjne pojedynczego emitenta?",
        answers: [
            {
                id: "3_11_1",
                label: "Nie większą niż 50% zainwestowanych środków",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_11_2",
                label: "Do 100 % zainwestowanych środków",
                points: 1,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_11_3",
                label: " Inwestując w akcje lub obligacje korporacyjne nie można ponieść straty",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "3_12",
        label: "3.12 Zadłużenie emitenta akcji lub obligacji w sprawozdaniu finansowym przedstawia:",
        answers: [
            {
                id: "3_12_1",
                label: "Rachunek przepływów pieniężnych (cashflow)",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "3_12_2",
                label: "Rachunek zysków i strat",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "3_12_3",
                label: "Bilans",
                points: 1,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_1_1",
        label: "4.1.1 Akcje spółek publicznych (notowanych na rynku regulowanym lub ASO):",
        answers: [
            {
                id: "4_1_1_1",
                label: "0 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_1_1_2",
                label: "Od 1 PLN do 10.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_1_1_3",
                label: "Od 10.000 PLN do 50.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_1_1_4",
                label: "Powyżej 50.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_1_2",
        label: "4.1.2 Akcje spółek niepublicznych:",
        answers: [
            {
                id: "4_1_2_1",
                label: "0 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_1_2_2",
                label: "Od 1 PLN do 10.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_1_2_3",
                label: "Od 10.000 PLN do 50.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_1_2_4",
                label: "Powyżej 50.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_1_3",
        label: "4.1.3 Obligacje korporacyjne:",
        answers: [
            {
                id: "4_1_3_1",
                label: "0 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_1_3_2",
                label: "Od 1 PLN do 10.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_1_3_3",
                label: "Od 10.000 PLN do 50.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_1_3_4",
                label: "Powyżej 50.000 PLN",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_2_1",
        label: "4.2.1 Akcje spółek publicznych (notowanych na rynku regulowanym lub ASO):",
        answers: [
            {
                id: "4_2_1_1",
                label: "0",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_2_1_2",
                label: "1-2",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_2_1_3",
                label: "3-10",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_2_1_4",
                label: "Powyżej 10",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_2_2",
        label: "4.2.2 Akcje spółek niepublicznych:",
        answers: [
            {
                id: "4_2_2_1",
                label: "0",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_2_2_2",
                label: "1-2",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_2_2_3",
                label: "3-10",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_2_2_4",
                label: "Powyżej 10",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_2_3",
        label: "4.2.3 Obligacje korporacyjne:",
        answers: [
            {
                id: "4_2_3_1",
                label: "0",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_2_3_2",
                label: "1-2",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_2_3_3",
                label: "3-10",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_2_3_4",
                label: "Powyżej 10",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "4_3",
        label: "4.3 Proszę wskazać rodzaje instrumentów finansowych posiadanych w okresie ostatnich 36 miesięcy:",
        answers: [
            {
                id: "4_3_1",
                label: "Akcje",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "4_3_2",
                label: "Obligacje",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "4_3_3",
                label: "Żadne z powyższych",
                points: 0,
                checked: false,
                disableQuestion: [],
                uncheckOthers: true
            }
        ],
        multipleAnswers: true,
        required: true,
        disabled: false
    },
    {
        id: "5",
        label: "5. Proszę wskazać planowany okres inwestycji w akcje lub obligacje:",
        answers: [
            {
                id: "5_1",
                label: "Krótki - do 1 roku",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "5_2",
                label: "Średni - od 1 roku do 3 lat",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "5_3",
                label: "Długi - powyżej 3 lat",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "6",
        label: "6. Proszę wskazać cel inwestycji (można zaznaczyć więcej niż 1 odpowiedź):",
        answers: [
            {
                id: "6_1",
                label: "Chcę zainwestować nadwyżkę środków finansowych celem ochrony przed inflacją (ochrona kapitału)",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "6_2",
                label: "Chcę zainwestować wolne środki finansowe celem wypracowania stabilnego zysku przewyższającego zysk z lokat bankowych przy umiarkowanym ryzyku inwestycyjnym",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "6_3",
                label: "Chcę zainwestować wolne środki finansowe celem osiągania wysokich stóp zwrotu z inwestycji przy akceptacji podwyższonego ryzyka",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: true,
        required: true,
        disabled: false
    },
    {
        id: "7_1",
        label: "7.1. Proszę wskazać, czy posiadasz cele inwestycyjne związane z czynnikami zrównoważonego rozwoju, tj. kwestiami środowiskowymi, społecznymi i pracowniczymi, kwestiami dotyczącymi poszanowania praw człowieka oraz przeciwdziałania korupcji i przekupstwu:",
        answers: [
            {
                id: "7_1_1",
                label: "Tak",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "7_1_2",
                label: "Nie (proszę pominąć pytanie 7.2.)",
                points: 0,
                checked: false,
                disableQuestion: ["7_2"]
            }, {
                id: "7_1_3",
                label: "Odmawiam odpowiedzi i akceptuję, że będzie to równoznaczne z nieposiadaniem celów inwestycyjnych związanych z czynnikami zrównoważonego rozwoju (proszę pominąć pytanie 7.2.)",
                points: 0,
                checked: false,
                disableQuestion: ["7_2"]
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "7_2",
        label: "7.2. Proszę wskazać, jakimi ofertami instrumentów finansowych jesteś zainteresowany/a:",
        answers: [
            {
                id: "7_2_1",
                label: "Jestem zainteresowany/a ofertami wszystkich instrumentów finansowych, tj. ofertami instrumentów finansowych uwzględniających czynniki zrównoważonego rozwoju oraz ofertami instrumentów finansowych nieuwzględniających czynników zrównoważonego rozwoju,",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "7_2_2",
                label: "Jestem zainteresowany/a ofertami wyłącznie instrumentów finansowych uwzględniających czynniki zrównoważonego rozwoju",
                ref: 1,
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "7_2_3",
                label: "Odmawiam odpowiedzi",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        refs: [
            {
                id: 1,
                text: "Wybierając odpowiedź Klient jest świadomy, że będzie otrzymywał od Domu Maklerskiego informacje o ofertach wyłącznie instrumentów finansowych uwzględniających czynniki zrównoważonego rozwoju, w związku z czym w sytuacji, gdy w ofercie produktowej Domu Maklerskiego nie będą znajdowały się tego rodzaju instrumenty finansowe, Klient nie będzie otrzymywał informacji o żadnych ofertach instrumentów finansowych, w szczególności nie będzie otrzymywał informacji o ofertach instrumentów finansowych nieuwzględniających czynników zrównoważonego rozwoju."
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "8",
        label: "8. Proszę określić stopień akceptacji ryzyka inwestycyjnego:",
        answers: [
            {
                id: "8_1",
                label: "Niski - akceptuję wyłącznie ryzyko związane z inwestowaniem w lokaty bankowe",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "8_2",
                label: "Średni - akceptuję ryzyko związane z inwestowaniem w spółki publiczne (notowane na rynku głównym GPW, NewConnect) lub w spółki zamierzające uzyskać status spółki publicznej (notowanej na rynku głównym GPW, NewConnect) w terminie około 36 miesięcy po przeprowadzeniu oferty publicznej lub związane z inwestowaniem w obligacje korporacyjne emitowane przez takie spółki",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "8_3",
                label: "Wysoki - akceptuję ryzyko związane z inwestowaniem w spółki typu start-up, znajdujące się na wczesnym etapie rozwoju, które mogą nigdy nie uzyskać statusu spółki publicznej (notowanej na rynku głównym GPW, NewConnect) lub mogą tego dokonać w terminie najwcześniej 2-3 lat po przeprowadzeniu oferty publicznej lub związane z inwestowaniem w obligacje korporacyjne emitowane przez takie spółki",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
    {
        id: "9",
        label: "9. Proszę określić stopień akceptacji ryzyka inwestycyjnego:",
        answers: [
            {
                id: "9_1",
                label: "Nie dopuszczam możliwości poniesienia żadnej, nawet przejściowej straty z inwestycji",
                points: 0,
                checked: false,
                disableQuestion: []
            },
            {
                id: "9_2",
                label: "Wycofam się z inwestycji dopiero w przypadku, gdy straty przekroczą 10% wartości zainwestowanych środków",
                points: 0,
                checked: false,
                disableQuestion: []
            }, {
                id: "9_3",
                label: "Nie wycofam się z inwestycji nawet w przypadku, gdy straty przekroczą 10% wartości zainwestowanych środków",
                points: 0,
                checked: false,
                disableQuestion: []
            }
        ],
        multipleAnswers: false,
        required: true,
        disabled: false
    },
];